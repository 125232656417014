.btn {
  text-transform: uppercase;
  padding: 5px 20px;
  margin-top: 20px;
  text-decoration: none;
  font-size: 15px;
  font-weight: bold;
  font-family: $body-font;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  text-align: center;
  border-radius: 4px 10px;
}

#enter-game {
  width: 250px;
  height: 250px;
  background-color: #000;
  background-size: cover;
  background-image: url(/assets/img/castlestatic.jpg);
  border-radius: 50%;
  border: 10px solid $green;
  outline: none;
  cursor: pointer;
  transition: all 0.7s ease-in-out;
  margin: 0 auto;
  margin-bottom: 20px;
  position: relative;

  #animated-castle {
    transition: opacity 0.7s ease-in-out;
    border-radius: 50%;
    background-image: url(/assets/img/fireworks.jpg);
    background-repeat: no-repeat;
    background-size: 4800%;
    background-position: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:hover {
    #animated-castle {
      opacity: 1;
      animation: fireworks 2s steps(47) infinite;
    }

    span {
      transform: scale(1.02) translateY(-2px);
      box-shadow: 0 5px 30px rgba(darken($green, 50%), 0.6);
    }
  }

  span {
    width: 110%;
    display: block;
    padding: 10px 20px;
    position: absolute;
    bottom: 15px;
    background: $green;
    color: $darkblue;
    font-family: $header-font;
    font-size: 15px;
    letter-spacing: 0.5px;
    left: -5%;
    z-index: 1;
    transition: all 0.3s;
    box-shadow: 0 5px 10px transparent;

    &::before,
    &::after {
      content: "";
      position: absolute;
      display: block;
      bottom: -0.2em;
      border: 1.5em solid darken($green, 10%);
      z-index: -1;
    }

    &::before {
      border-right-width: 1.5em;
      border-left-color: transparent;
      left: -1.7em;
    }

    &::after {
      border-right-width: 1.5em;
      border-right-color: transparent;
      right: -1.7em;
    }
  }
}

@keyframes fireworks {
  100% {
    background-position: 100%;
  }
}
