input:not([type=radio]):not([type=checkbox]) {
  width: 100%;
  padding: 10px 15px;
  outline: none;
  //background: rgba($darkblue, 0.1);
  font-size: 15px;
  color: $darkblue;
  margin: 5px 0;
  font-family: $body-font, sans-serif;
  border: 2px solid rgba($darkblue, 0.3);
  transition: all 0.3s ease-in-out;
  border-radius: 4px;

  &:focus {
    border-color: rgba($darkblue, 1);
  }

  &[type="checkbox"] {
    width: auto;
    margin-right: 10px;
  }
}

form {
  width: 100%;

  label {
    font-family: $header-font;
    text-transform: uppercase;
    letter-spacing: 0.2px;
  }
}

.has-error {
  input {
    border-color: $red;
  }

  label {
    color: $red;
  }
}

.form-group {
  margin-bottom: 15px;
}

.btn.btn-green {
  background: $green;
  padding: 10px 20px;
  margin: 5px 0 10px;
  border: 2px solid $green;
  color: #fff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, .1);

  &:hover,
  &:focus,
  &.active {
    background: rgba($green, 20%);
  }
}

.btn-register,
.btn-logout {
  outline: none;
  padding: 10px 20px;
  display: block;
  margin: 5px 0;
  color: $darkblue;
  background: $white;
  border: 2px solid transparent;

  &:not(.btn-logout) {
    width: 100%;
  }
}

.forgot-password {
  font-family: $body-font;
  display: block;
  text-align: right;

  &:hover,
  &:focus,
  &.active {
    text-decoration: underline;
  }

  &:focus {
    outline-color: transparent;

    small {
      outline: $darkblue;
      outline-style: solid;
      outline-offset: 5px;
    }
  }
}
