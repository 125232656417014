/*
  COLORS
*/
$white: #fff;
$black: #000;
$darkblue: #112a46;
$green: #9ddc47;
$pink: #b24e62;
$lightpurple: #8d43a3;
$purple: #6232ba;
$darkpurple: #3e1673;
$gray: #f5f5f5;
$red: #dc4747;

@use "sass:map";

$theme-colors: (
        "white": $white,
        "black": $black,
        "darkblue": $darkblue,
        "green": $green,
        "pink": $pink,
        "lightpurple": $lightpurple,
        "purple": $purple,
        "darkpurple": $darkpurple,
        "gray": $gray
);

@each $name, $color in $theme-colors {
  .bg-#{$name} {
    background: $color;
    color: $white;

    &:focus {
      outline: none;
    }

    .btn.bg-white {
      color: $color;
      border: none !important;
    }

    input.bg-white {
      color: $color;
      border: none !important;
    }
  }
  .text-#{$name} {
    color: $color;
  }
  .btn.bg-#{$name} {
    border: 2px solid $color;

    &:hover {
      background: rgba($color, 0);
      border-color: $color;
      color: $color !important;
    }
  }
}

/* RESET */
* {
  box-sizing: border-box;
}

/* TYPOGRAPHY */
$header-font: "Righteous", sans-serif;
$body-font: "Montserrat", sans-serif;

* {
  font-family: $body-font;
}

.font-header {
  font-family: $header-font;
}

p,
li {
  font-size: 15px;
  //max-width: 450px;
  margin-bottom: 10px;
  line-height: 1.5em;
}

p {
  margin-bottom: 20px;
}

small {
  font-size: 10px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $header-font;
  text-transform: uppercase;
}

.bg-gray,
.bg-white {
  color: $darkblue;
}

.bg-brandgradient-radialcenter {
  background: $pink;
  background: radial-gradient(
                  ellipse at bottom center,
                  $pink 0%,
                  $lightpurple 25%,
                  $purple 50%,
                  $darkpurple 80%
  );
}

.bg-brandgradient-diag {
  background: $pink;
  background: linear-gradient(
                  to bottom right,
                  rgba($pink, 0.95) 0%,
                  rgba($lightpurple, 0.95) 25%,
                  rgba($purple, 0.95) 50%,
                  rgba($darkpurple, 0.95) 80%
  );
}

.bg-brandgradient {
  background: $pink;
  background: linear-gradient(
                  to right,
                  rgba($pink, 1) 0%,
                  rgba($lightpurple, 1) 33%,
                  rgba($purple, 1) 66%,
                  rgba($darkpurple, 1) 100%
  );
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

h2 {
  position: relative;
  margin-bottom: 30px;
  line-height: 1.4rem;

  &::before {
    position: absolute;
    content: "";
    width: 30px;
    height: 2px;
    background: #fff;
    bottom: -10px;
  }

  &.text-blue::before {
    background: $darkblue;
  }

  &.no-line {
    margin-bottom: 20px;

    &::before {
      content: none;
    }
  }

  &.text-darkblue::before {
    background: $darkblue;
  }
}
.bg-white, .bg-gray {
  h2::before {
    background: #000;
  }
}

a {
  text-decoration: none;
  color: $green;
  font-weight: bold;
}
a:hover {
  text-decoration: underline;
}
.bg-purple a {
  color: $white;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.fit-cover {
  object-fit: cover;
}

.top-left {
  top: 0;
  left: 0;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.inner-page p,
.inner-page li {
  max-width: unset;
}

.small-highlight {
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 10px;
  display: block;
}

ul,
ol {
  margin: 10px 0;
  padding-left: 40px;
}

.roadmap-complete {
  background: rgba($green, 0.1);
  padding: 10px;
}

.text-transform-none {
  text-transform: none;
}
