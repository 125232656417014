/** LARGE SCREENS **/

/** MEDIUM **/

@media screen and (max-width: 1050px) {
  .hero {
    height: 40vh;
  }

  .row {
    flex-wrap: wrap;
  }

  @for $i from 1 to 12 {
    .row .col-#{$i} {
      flex: 0 0 93.3333333333%;
      max-width: 93.3333333333%;
    }

  }

  .inner-page-content,
  .blurb {
    flex: 0 0 93.3333333333%;
    max-width: 93.3333333333%;
  }
  .blurb {
    margin: auto;
  }
  .col-1.spacer {
    display: none;
  }
  .login {
    margin-bottom: 20px;
  }
  p,
  li,
  .inner-page p,
  .inner-page li {
    max-width: 800px;
  }

  .inner-page-content {
  }
  .hero-text.welcome-hero {
    width: 50%;
  }
}


.mobile-only {
  display: none;
}
/** SMALL **/
@media screen and (max-width: 800px) {
  p,
  li,
  .inner-page p,
  .inner-page li {
    max-width: unset;
  }

  .desktop-only {
    display: none !important;
  }

  .mobile-only {
    display: initial;
  }

  @for $i from 1 to 12 {
    .row .col-#{$i} {
      flex: 0 0 100%;
      max-width: 100%;
    }

  }
}

