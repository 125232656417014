.container-fluid {
  width: 100%;
  margin: 0 -0.5rem;
}

.row {
  display: flex;
  justify-content: center;
}

@for $i from 1 to 12 {
  .col-#{$i} {
    padding: 0.5rem;
    flex: 0 0 calc(100% / 12 * $i);
    max-width: calc(100% / 12 * $i);
    align-content: stretch;
  }
}

.inner {
  padding: 1.25rem;
  border-radius: 7px 25px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;

  p {
    flex-grow: 2;
  }
}

@for $j from 0 to 6 {
  .py-#{$j} {
    padding-top: calc(0.5rem * $j);
    padding-bottom: calc(0.5rem * $j);
  }
  .px-#{$j} {
    padding-right: calc(0.5rem * $j);
    padding-left: calc(0.5rem * $j);
  }
  .p-#{$j} {
    padding: calc(0.5rem * $j);
  }
  .pt-#{$j} {
    padding-top: calc(0.5rem * $j);
  }
  .pb-#{$j} {
    padding-bottom: calc(0.5rem * $j);
  }
  .pr-#{$j} {
    padding-right: calc(0.5rem * $j);
  }
  .pl-#{$j} {
    padding-left: calc(0.5rem * $j);
  }
  .my-#{$j} {
    margin-top: calc(0.5rem * $j);
    margin-bottom: calc(0.5rem * $j);
  }
  .mx-#{$j} {
    margin-right: calc(0.5rem * $j);
    margin-left: calc(0.5rem * $j);
  }
  .m-#{$j} {
    margin: calc(0.5rem * $j);
  }
  .mt-#{$j} {
    margin-top: calc(0.5rem * $j);
  }
  .mb-#{$j} {
    margin-bottom: calc(0.5rem * $j);
  }
  .mr-#{$j} {
    margin-right: calc(0.5rem * $j);
  }
  .ml-#{$j} {
    margin-left: calc(0.5rem * $j);
  }
}
