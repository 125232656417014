@import "./scss/settings";
/* COLOR DEFINITIONS, BASE STYLES */
@import "./scss/flex";
/* FLEXBOX */
@import "./scss/grid";
/* GRID COLUMNS/ROWS */
@import "./scss/btn";
/* BUTTON SETTINGS */
/* may combine into settings */
@import "./scss/form";
/* FORM SETTINGS */
@import "./scss/mediaqueries"; /* MEDIA QUERY SETTINGS */

body {
  margin: 0;
    //background: radial-gradient(
    //                ellipse at bottom center,
    //                $pink 0%,
    //                $lightpurple 25%,
    //                $purple 50%,
    //                $darkpurple 80%);
  //background-image: linear-gradient(to bottom, #6232ba 33%, #14128b 100%);
  background-image: linear-gradient(to bottom, #8d43a3 0%, #6232ba 66%, #3e1673 100%), linear-gradient(to bottom, #8d43a3 0%, #8d43a3 100%);
  background-position: 0 30vh, 0 0;
  background-repeat: repeat-x;
  background-size: 100% 100vh;
  background-color: #3e1673;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

blockquote {
  border-left: 2px solid #6232ba;
  margin-left: 1em;
  padding-left: 1em;
}

// MODAL

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1200;

  .header {
    padding: 0 1.25rem;
    text-transform: uppercase;
    font-family: $header-font;
    margin: -1.25rem -1.25rem 1.25rem -1.25rem;
    width: calc(100% + 2.5rem);

    .header-container {
      padding: 0 0 0 20px;
      justify-content: space-between;
    }
  }
}

.btn-close {
  margin: 0;
  width: 50px;
  height: 50px;
  cursor: pointer;
  padding: 0;

  .close-line-container {
    width: 30px;
    height: 17px;
    position: relative;
    margin-top: 10px;
    transform: scale(0.8);
  }

  .close-line {
    width: 30px;
    height: 3px;
    position: absolute;
    left: 0;
    border-radius: 10px;
    pointer-events: none;
    transition: all 0.3s ease-in-out;

    &:first-child {
      transform: rotate(-45deg);
    }

    &:last-child {
      transform: rotate(45deg);
    }
  }
}
